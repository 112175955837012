import {mapActions, mapGetters, mapMutations} from "vuex";

import {dynamicHead} from '@/mixins/dynamic-head.js'
import cartItem from "@/modules/cart/components/cart-item/index.vue";

export default {
  name: "cart",
  mixins: [dynamicHead],
  components:{
    cartItem
  },
  data(){
    return{
      crumbs: [
        {name: 'home', title: this.$t('mainLabel'), slug: ''},
        {name: 'cart', title: this.$t('cart'), slug: ''}
      ],
      params: {
        title: '',
        description: '',
        keywords: '',
        image: '',
      },
    }
  },
  watch: {
    '$store.getters.translation': {
      immediate: true,
      handler(newVal) {
        this.crumbs[0].title = newVal.mainLabel
        this.crumbs[1].title = newVal.cart
      }
    },
  },
  created() {
    this.fetchMeta('home').then(()=>{
      this.setParams()
      this.setMeta()
    }).catch(error=>{
      console.log(error)
    })
  },
  computed:{
    ...mapGetters({
      basket: 'basket/basket',
      meta: 'meta/meta',
    })
  },

  methods:{
    ...mapActions({
      fetchMeta: 'meta/GET_META',
    }),
    ...mapMutations({
    }),
    setParams(){
      this.params.title = this.meta.metaTitle;
      this.params.description = this.meta.metaDescription;
      this.params.keywords = this.meta.metaKeywords;
      this.params.image = this.meta.metaImage;
    }
  }
}