import {mapActions, mapGetters} from "vuex";

export default {
  name: "cart-item",
  props: {
    data: {
      type: Object,
      default: ()=> {}
    },
  },
  components:{
  },
  data(){
    return{
    }
  },
  created() {

  },
  computed:{
    ...mapGetters({
      basketUpdateLoading: 'basket/basketUpdateLoading'
    })
  },
  methods:{
    ...mapActions({
      updateBasket: 'basket/UPDATE_BASKET',
      deleteAttr: 'basket/REMOVE_ATTR',
    }),
    updateBasketItem(type){
      this.updateBasket({product_id: this.data.productId, id: this.data.id, count: type}).then(()=>{
        this.$toasted.success(this.$t('successCartUpdate'))
      }).catch(error => {
        this.$toasted.error(error)
      })
    },
    removeAttr(id){
      this.deleteAttr({attr_id: id, id: this.data.id}).then(()=>{
        this.$toasted.success(this.$t('successCartUpdate'))
      }).catch(error => {
        this.$toasted.error(error)
      })
    }
  }
}